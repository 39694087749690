export function formatReadingTime(minutes: number) {
  let cups = Math.round(minutes / 5);
  if (cups > 4) {
    return `${new Array(Math.round(cups / 4))
      .fill("🍚")
      .join("")} ≈ ${minutes} mins`;
  } else {
    return `${new Array(cups || 1).fill("🍵").join("")} ≈ ${minutes} mins`;
  }
}
